<script setup lang="ts">
import { debounce } from 'lodash-es'
import { ValidationProvider } from 'vee-validate'
import { computed, nextTick, ref, watch, onMounted } from 'vue'
import { directive as vOnClickaway } from 'vue-clickaway'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useInput, type InputValue } from '/~/composables/base'

interface Option {
  label: string
  [key: string]: any
}

type AsyncSelectInputValue = InputValue | object

const props = withDefaults(
  defineProps<{
    // base input props
    name?: string
    label?: string
    icon?: string
    placeholder?: string
    error?: string | string[]
    required?: boolean
    disabled?: boolean
    nolabel?: boolean
    floated?: boolean
    validation?: object
    // component props
    fetch?: (input: string) => Promise<Option[]>
    look?: string
    iconPlain?: boolean
    value?: string | number | object
    valueAsObject?: boolean
    mask?: object | string
    defaultOptions?: Option[]
    fetchOnFocus?: boolean
    entryClass?: string
    entryClassExpand?: string
    entryClassSelected?: string
    inputClass?: string | any[]
    labelClass?: string
    isStaticData?: boolean
    isFetchingSelectedItem?: boolean
  }>(),
  {
    // base input defaults
    name: '',
    label: '',
    icon: 'plain/search',
    value: '',
    placeholder: '',
    error: '',
    required: false,
    disabled: false,
    nolabel: true,
    floated: false,
    validation: () => ({}),
    // component defaults
    fetch: null,
    look: '',
    iconPlain: true,
    valueAsObject: false,
    mask: null,
    defaultOptions: null,
    fetchOnFocus: false,
    entryClass: 'rounded h-10',
    entryClassExpand: 'rounded py-1.5',
    entryClassSelected:
      'rounded-sm h-12 text-eonx-neutral-800 border w-full flex items-center p-3',
    inputClass: '',
    labelClass: '',
    isStaticData: false,
    isFetchingSelectedItem: false,
  }
)

const emit = defineEmits<{
  (e: 'update:value', value: AsyncSelectInputValue | object): void
  (e: 'change', value: AsyncSelectInputValue | object | null): void
  (e: 'input', value: AsyncSelectInputValue): void
  (e: 'blur', component: any): void
  (e: 'clear', component: any | null): void
  (e: 'unmasked', value: any): void
}>()

const {
  validationProviderRef,
  isFocused,
  validateOnBlur,
  validate,
  onInput,
  syncValue,
  reset,
  validateSilent,
} = useInput(props, emit)

const baseInput = ref<InstanceType<typeof BaseInput>>()
const list = ref<HTMLElement>()
const inputValue = ref<AsyncSelectInputValue>(props.value || '')
const selectedValue = ref<AsyncSelectInputValue>()
const options = ref<Option[]>(props.defaultOptions || [])

const isExpanded = computed(
  () =>
    isFocused.value &&
    (String(inputValue.value).length > 1 || props.isStaticData) &&
    options.value
)

const isLoading = computed(
  () => isFocused.value && String(inputValue.value).length > 0 && !options.value
)

const isEmpty = computed(() => options.value?.length === 0)

const debounceTimeout = computed(() => (props.isStaticData ? 0 : 400))

const debouncedFetch = debounce((inputValue) => {
  if (props.fetch instanceof Function) {
    props
      .fetch(inputValue)
      .then((data) => {
        options.value = data
      })
      .catch((error) => {
        console.error(error)
      })
  }
}, debounceTimeout.value)

const fetchOptions = (input: AsyncSelectInputValue) => {
  debouncedFetch(input)
}

watch(
  () => props.value,
  (newVal) => {
    inputValue.value = newVal || ''
  }
)

function onClickSelected() {
  clear()
  selectedValue.value = null
  emit('update:value', null)
  emit('change', null)
  inputValue.value = ''
  nextTick(() => baseInput.value?.focus())
}

function onSearchFocus() {
  isFocused.value = true
  if ((props.fetchOnFocus && inputValue.value) || props.isStaticData) {
    fetchOptions(inputValue.value)
  }
}

function onSearchBlur(event?: FocusEvent) {
  if (
    event?.relatedTarget &&
    list.value?.contains(event.relatedTarget as Node)
  ) {
    return
  }

  validateOnBlur(inputValue.value)
  emit('blur', validationProviderRef.value)
  isFocused.value = false
}

function onSearchCleared() {
  clear()
  emit('clear', inputValue.value ? null : validationProviderRef.value)
}

function onSearchInput(value: AsyncSelectInputValue) {
  onInput(value)

  if (String(value).length > 1) {
    fetchOptions(value)
    isFocused.value = true
  } else {
    clear()
    if (props.isStaticData) fetchOptions(value)
  }
}

function onSelectItem(item: Option) {
  // TODO: refactor so we dont need to pass the component instance as second argument
  // TODO: refactor so we always emit change same as model value
  const value = props.valueAsObject ? item : item.label

  emit('update:value', value)
  emit('change', item, validationProviderRef.value)
  nextTick(() => {
    inputValue.value = value
    selectedValue.value = item
  })

  if (props.validation?.mode !== 'passive') {
    validate(inputValue.value)
  }

  close()
}

function clear() {
  emit('clear', inputValue.value ? null : undefined)
  options.value = null
  reset()
}

function close() {
  if (isFocused.value) {
    isFocused.value = false
    onSearchBlur()
  }
}

onMounted(() => {
  syncValue(inputValue.value)
  validateSilent()
})
</script>

<template>
  <div v-on-clickaway="close" class="relative">
    <validation-provider
      v-slot="{ errors }"
      v-bind="validation"
      ref="validationProviderRef"
      mode="passive"
      :name="validation.name || label"
      slim
      :detect-input="false"
    >
      <button
        v-if="$slots.inputValueSlot && selectedValue"
        :class="entryClassSelected"
        @click="onClickSelected"
      >
        <slot name="inputValueSlot" :value="selectedValue" />
        <base-icon
          class="absolute right-3 z-10"
          svg="plain/chevron-bottom"
          size="sm"
        />
      </button>
      <base-input
        v-else
        ref="baseInput"
        v-model="inputValue"
        clearable
        :error="isFetchingSelectedItem ? '' : errors[0] || error"
        :icon="icon"
        :icon-plain="iconPlain"
        :look="look"
        :label="label"
        :nolabel="nolabel"
        :placeholder="placeholder"
        :disabled="!fetch || isFetchingSelectedItem || disabled"
        :floated="floated"
        :required="required"
        :loading="isLoading || isFetchingSelectedItem"
        :entry-class="entryClass"
        :input-class="inputClass"
        :label-class="labelClass"
        :mask="mask"
        :name="name"
        :validation="{ vid: `${name} input`, disabled: true }"
        @input="onSearchInput"
        @focus="onSearchFocus"
        @cleared="onSearchCleared"
        @unmasked="emit('unmasked', $event)"
      />
    </validation-provider>

    <transition name="fade">
      <div
        v-show="isExpanded"
        ref="list"
        class="absolute left-0 top-full z-10 max-h-80 w-full overflow-y-auto rounded border bg-default"
        :class="entryClassExpand"
      >
        <slot v-if="isEmpty" name="noOptionsFound">
          <div class="px-3 py-1.5">
            <span class="text-sm">No records were found</span>
          </div>
        </slot>
        <ul v-else class="divide-y divide-eonx-neutral-300">
          <li
            v-for="(item, idx) in options"
            :key="'suburb-' + idx"
            :tabindex="0"
            class="cursor-pointer truncate hover:bg-primary-lighten"
            @click="onSelectItem(item)"
          >
            <slot
              v-if="$slots.option"
              name="option"
              :option="item"
              :idx="idx"
            />
            <div v-else class="px-3.5 py-2">
              {{ item.label }}
            </div>
          </li>
        </ul>
        <slot name="afterOptions" />
      </div>
    </transition>
  </div>
</template>
